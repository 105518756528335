/**
 * Foursource page
 * https://foursource.com/buyers
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

import { pageTexts } from "../content/buyers";
import { associates } from "../content/pages/associatesPrincipal";

// components
import Layout from "../components/layout";
import TextImgBackgroundFull from "../components/textImgBackgroundFull";
import Associates from "../components/_page/associates";
import SideBySide from "../components/sideBySide";

const MOBILE_WIDTH = 992;

class buyersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobile: (typeof window !== "undefined") ? (window.innerWidth <= MOBILE_WIDTH ? true : false):'',
    };
    this.t = props.t;
    this.text = pageTexts(this.t);
    this.associates = associates(this.t);
  }


  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MOBILE_WIDTH ? true : false;
    this.setState({
      mobile: show,
    });
  };


  render() {
    return (
      <>
        <Layout pageName="buyers" whiteMenu={false}>
          <TextImgBackgroundFull info={this.state.mobile ? this.text.topInfoMobile : this.text.topInfo}></TextImgBackgroundFull>
          {this.text.pageContent.map((content, i) => (
            <SideBySide key={i} info={content.info} isTextFirst={content.isTextFirst} cta={content.cta}></SideBySide>
          ))}
          <div className="pt-75 backgroundGrey topDiagonal">
            <Associates info={this.associates.cardsInfo}></Associates>
          </div>
        </Layout>
      </>
    );
  }
}

export default withTrans(buyersPage);
