import CheckIcon from "../images/icon/check.svg";
import Utm from "../components/_global/utm";

// images
import TopBackground from "../images/for-buyers.jpeg";
import TopBackgroundMobile from "../images/headersMobile/2-for-buyers-mobile.jpg";
import Slide1 from "../images/slides/buyer/apparel-showrooms-buyer-view.png";
import Slide2 from "../images/slides/buyer/fabric-catalogue-search-buyer-view.png";
import Slide3 from "../images/slides/buyer/company-profile-overview-buyer-view.png";
import Slide4 from "../images/slides/buyer/add-files-comments-buyer-view.png";
import Slide5 from "../images/slides/buyer/show-selected-labels-in-search-buyer-view.png";
import Slide6 from "../images/slides/buyer/catalogue-search-3d-filter-buyer-view.png";
import Slide7 from "../images/slides/buyer/3d-fabric-light-box-buyer-view.png";
import Slide8 from "../images/slides/buyer/manage-quotes-buyer-view.png";
import Slide9 from "../images/slides/buyer/apparel-rfqs-buyer-view.png";

export const pageTexts = (t) => {
  return {
    topInfo: {
      title: {
        text: t("buyers.topPretitle"),
        colorClass: "colorLight",
        extraClass: "font24 fontWeight400 ",
      },
      subtitle: {
        text: t("buyers.topTitle"),
        colorClass: "colorLight",
        extraClass: "font50 ",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackground,
        alt: t("buyers.imgAlt"),
      },
    },
    topInfoMobile: {
      title: {
        text: t("buyers.topPretitle"),
        colorClass: "colorLight",
        extraClass: "font24 fontWeight400 shadowEffect",
      },
      subtitle: {
        text: t("buyers.topTitle"),
        colorClass: "colorLight",
        extraClass: "font50 shadowEffect",
      },
      bottomDiagonal: true,
      opacity: false,
      image: {
        src: TopBackgroundMobile,
        alt: t("buyers.imgAlt"),
      },
    },
    pageContent: [
      {
        info: {
          title: {
            text: t("buyers.joinIndustry"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("buyers.manageRelationships"), t("buyers.exploreProfiles"), t("buyers.aceessShowrooms"), t("buyers.freeDBAccess")],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: true,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide1, Slide2, Slide3],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("buyers.manageLifecycle"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("buyers.createDatabase"), t("buyers.attatchImages"), t("buyers.storeKnowledgeData"), t("buyers.automaticallyNotified")],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide4, Slide5],
        },
        isTextFirst: false,
      },
      {
        info: {
          title: {
            text: t("buyers.createLibrary"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("buyers.manageMaterials"), t("buyers.importMaterials"), t("buyers.requestDigitalization"), t("buyers.saveCosts")],
            colorClass: "colorDark",
          },
          bottomDiagonal: true,
          pushTop: false,
          topDiagonal: true,
          backgroundGrey: true,
          customPB: true,
          customPBText: true,
          images: [Slide6, Slide7],
        },
        isTextFirst: true,
      },
      {
        info: {
          title: {
            text: t("buyers.increaseMargins"),
            colorClass: "colorDark",
          },
          icon: { fontAwesome: false, src: CheckIcon, alt: t("homepage.checkAlt")},
          separator: true,
          item: {
            text: [t("buyers.createRequests"), t("buyers.receiveOffers"), t("buyers.manageSelection"), t("buyers.reduceTimes")],
            colorClass: "colorDark",
          },
          bottomDiagonal: false,
          topDiagonal: false,
          backgroundGrey: false,
          customPB: false,
          customPBText: true,
          images: [Slide8, Slide9],
        },
        isTextFirst: false,
        cta: {
          text: t("buyers.joinFree"),
          url:process.env.GATSBY_CHINA === "true" ? `${process.env.APP_CHINA_URL}/signup/` : Utm(`${process.env.APP_URL}/signup/`),
          type: "mainColor",
          extraClass: "bigWidth",
        },
      },
    ],

  };
};
