import * as React from "react";
import "../style/components/slideshow.scss";

// slide
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";

const HIGH_RES = 992;
class Slideshow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      highRes: window.innerWidth >= HIGH_RES ? true : false,
    };
  }
  render() {
    const { images } = this.props;
    const { highRes } = this.state;
    return (
      <div className="slide-container h-100">
        <Slide>
          {images.map((image, i) => (
            <div className="each-slide" key={i}>
              {highRes ? (
                <img
                  className="w-100"
                  src={image}
                  alt="slide"
                />
              ) : (
                <div
                  className="h-100 display-flex align-items-center justify-content-center"
                  style={{ backgroundImage: `url(${image})` }}
                ></div>
              )}
            </div>
          ))}
        </Slide>
      </div>
    );
  }
}

export default Slideshow;
