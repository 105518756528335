import * as React from "react";
import classNames from "classnames";
import "../style/components/sideBySide.scss";

// components
import BulletsSection from "./bulletsSection";
import Slideshow from "./slideshow";
import Button from "./_partials/_button";

const MAX_MOBILE_WIDTH = 992;

class SideBySide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileFlag: window.innerWidth <= MAX_MOBILE_WIDTH ? true : false,
    };
    this.t = props.t;
  }

  componentDidMount = () => {
    window.addEventListener("resize", this.handleResize, true);
  };

  handleResize = () => {
    let show = window.innerWidth <= MAX_MOBILE_WIDTH ? true : false;
    this.setState({
      mobileFlag: show,
    });
  };

  render() {
    const { isTextFirst, info, cta } = this.props;

    return (
      <>
        <div
          className={classNames(
            "paddingSidesBig",
            "pt-20",
            "pb-30",
            { backgroundGrey: info.backgroundGrey },
            { "bottomDiagonal pb-100": info.bottomDiagonal },
            { "topBottomDiagonal pt-100": info.topDiagonal },
            { pushTopDiagonal: info.pushTop },
            { noRight: isTextFirst },
            { noLeft: !isTextFirst }
          )}
        >
          <div className="row heightSlides">
            <div
              className={
                (isTextFirst || this.state.mobileFlag) ? "col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5" : "col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 height-slide p-0"
              }
            >
              {(isTextFirst || this.state.mobileFlag) ? (
                <div className="pt-30">
                  <BulletsSection info={info}></BulletsSection>
                </div>
              ) : (
                <div className="h-100 pb-md-40 pb-sm-20">
                  {info.images.length > 1 ? (
                    <Slideshow images={info.images}></Slideshow>
                  ) : (
                    <img className="w-100" src={info.images[0]} alt="Foursource platform" />
                  )}
                </div>
              )}
            </div>
            <div
              className={
                (isTextFirst || this.state.mobileFlag) ? "col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7 height-slide p-0" : "col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5"
              }
            >
              {(isTextFirst || this.state.mobileFlag) ? (
                <div className="h-100 pb-md-40 pb-sm-20">
                  {info.images.length > 1 ? (
                    <Slideshow images={info.images}></Slideshow>
                  ) : (
                    <img className="w-100" src={info.images[0]} alt="Foursource platform" />
                  )}
                </div>
              ) : (
                <div className="pt-30">
                  <BulletsSection info={info}></BulletsSection>
                </div>
              )}
            </div>
          </div>
          {cta && (
            <div className={classNames("row", "justify-content-center", "pt-30")}>
              <Button cta={cta}></Button>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default SideBySide;
